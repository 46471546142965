var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":_vm.$t('save'),"title":_vm.$t('editSchedule'),"max-width":500},on:{"save":_vm.loadItems},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('ScheduleForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,3884892193),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('ObjectRefsDialog',{attrs:{"object-title":"schedule","object-refs":_vm.itemRefs,"project-id":_vm.projectId},model:{value:(_vm.itemRefsDialog),callback:function ($$v) {_vm.itemRefsDialog=$$v},expression:"itemRefsDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('deleteSchedule'),"text":_vm.$t('askDeleteSchedule')},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('schedule')))]),_c('v-spacer'),(_vm.can(_vm.USER_PERMISSIONS.manageProjectResources))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v(_vm._s(_vm.$t('newSchedule'))+" ")]):_vm._e()],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.setActive(item.id, item.active)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name || '—'))])]}},{key:"item.tpl_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":'/project/' + item.project_id +
          '/templates/' + item.template_id}},[_vm._v(_vm._s(item.tpl_name)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [(_vm.openedItems.some(function (template) { return template.id === item.id; }))?_c('td',{attrs:{"colspan":headers.length}},[_c('TaskList',{staticStyle:{"border":"1px solid lightgray","border-radius":"6px","margin":"10px 0"},attrs:{"template":item,"limit":5,"hide-footer":true}})],1):_vm._e()]}}],null,false,62818047)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }