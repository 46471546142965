export default {
  checkInterval: 'Überprüfungsintervall',
  newCommitCheckInterval: 'Neues Commit-Überprüfungsintervall',
  schedule: 'Zeitplan',
  newSchedule: 'Neuer Zeitplan',
  deleteSchedule: 'Zeitplan löschen',
  editSchedule: 'Zeitplan bearbeiten',
  backup: 'Projekt sichern',
  downloadTheProjectBackupFile: 'Die Projekt-Backup-Datei herunterladen (im JSON-Format)',
  restoreProject: 'Projekt wiederherstellen...',
  incorrectUsrPwd: 'Falscher Benutzername oder Passwort',
  askDeleteUser: 'Möchten Sie diesen Benutzer wirklich löschen?',
  askDeleteTemp: 'Möchten Sie diese Vorlage wirklich löschen?',
  askDeleteEnv: 'Möchten Sie diese Umgebung wirklich löschen?',
  askDeleteInv: 'Möchten Sie diesen Inventar wirklich löschen?',
  askDeleteKey: 'Möchten Sie diesen Schlüssel wirklich löschen?',
  askDeleteRepo: 'Möchten Sie dieses Repository wirklich löschen?',
  askDeleteProj: 'Möchten Sie dieses Projekt wirklich löschen?',
  askDeleteTMem: 'Möchten Sie dieses Teammitglied wirklich löschen?',
  askDeleteSchedule: 'Möchten Sie diesen Zeitplan wirklich löschen?',
  edit: 'Bearbeiten',
  nnew: 'Neu',
  keyFormSshKey: 'SSH-Schlüssel',
  keyFormLoginPassword: 'Anmeldung mit Passwort',
  keyFormNone: 'Keine',
  incorrectUrl: 'Falsche URL',
  username: 'Benutzername',
  username_required: 'Benutzername ist erforderlich',
  dashboard: 'Dashboard',
  history: 'Verlauf',
  activity: 'Aktivität',
  settings: 'Einstellungen',
  signIn: 'Anmelden',
  password: 'Passwort',
  changePassword: 'Passwort ändern',
  editUser: 'Benutzer bearbeiten',
  newProject: 'Neues Projekt',
  close: 'Schließen',
  newProject2: 'Neues Projekt...',
  demoMode: 'DEMO-MODUS',
  task: 'Aufgabe #{expr}',
  youCanRunAnyTasks: 'Sie können beliebige Aufgaben ausführen',
  youHaveReadonlyAccess: 'Sie haben nur Lesezugriff',
  taskTemplates: 'Aufgabenvorlagen',
  inventory: 'Inventar',
  environment: 'Umgebungsvariablen',
  keyStore: 'Schlüsselspeicher',
  repositories: 'Repositories',
  darkMode: 'Dunkelmodus',
  team: 'Team',
  users: 'Benutzer',
  editAccount: 'Konto bearbeiten',
  signOut: 'Abmelden',
  error: 'Fehler',
  refreshPage: 'Seite aktualisieren',
  relogin: 'Erneut anmelden',
  howToFixSigninIssues: 'So beheben Sie Anmeldeprobleme',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Zuerst benötigen Sie Zugriff auf den Server, auf dem Semaphore läuft.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Führen Sie den folgenden Befehl auf dem Server aus, um vorhandene Benutzer anzuzeigen:',
  semaphoreUserList: 'semaphore Benutzerliste',
  youCanChangePasswordOfExistingUser: 'Sie können das Passwort des vorhandenen Benutzers ändern:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore Benutzer ändern --login benutzer123 --passwort {makePasswordExample}',
  orCreateNewAdminUser: 'Oder erstellen Sie einen neuen Administratorbenutzer:',
  close2: 'Schließen',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: 'Haben Sie kein Konto oder können Sie sich nicht anmelden?',
  password2: 'Passwort',
  cancel: 'Abbrechen',
  noViews: 'Keine Ansichten',
  addView: 'Ansicht hinzufügen',
  editEnvironment: 'Umgebung bearbeiten',
  deleteEnvironment: 'Umgebung löschen',
  newEnvironment: 'Neue Umgebung',
  environmentName: 'Umgebungsname',
  extraVariables: 'Zusätzliche Variablen',
  enterExtraVariablesJson: 'Geben Sie zusätzliche Variablen im JSON-Format ein...',
  environmentVariables: 'Umgebungsvariablen',
  enterEnvJson: 'Geben Sie die Umgebungs-JSON ein...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Umgebung und zusätzliche Variablen müssen gültiges JSON sein. Beispiel:',
  dashboard2: 'Dashboard',
  ansibleSemaphore: 'Semaphore UI',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Es tut uns leid, aber <%= htmlWebpackPlugin.options.title %> funktioniert nicht richtig, ohne dass JavaScript aktiviert ist. Bitte aktivieren Sie es, um fortzufahren.',
  deleteInventory: 'Inventar löschen',
  newInventory: 'Neues Inventar',
  name: 'Name',
  userCredentials: 'Benutzerdaten',
  sudoCredentialsOptional: 'Sudo-Daten (optional)',
  type: 'Typ',
  pathToInventoryFile: 'Pfad zur Inventar-Datei',
  enterInventory: 'Inventar eingeben...',
  staticInventoryExample: 'Statisches Inventar-Beispiel:',
  staticYamlInventoryExample: 'Statisches YAML-Inventar-Beispiel:',
  keyName: 'Schlüsselname',
  loginOptional: 'Anmeldung (optional)',
  usernameOptional: 'Benutzername (optional)',
  privateKey: 'Privater Schlüssel',
  override: 'Überschreiben',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Verwenden Sie diesen Schlüsseltyp für HTTPS-Repositories und für Playbooks, die keine SSH-Verbindungen verwenden.',
  deleteKey: 'Schlüssel löschen',
  newKey: 'Neuer Schlüssel',
  create: 'Erstellen',
  newTask: 'Neue Aufgabe',
  cantDeleteThe: 'Kann {objectTitle} nicht löschen',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} kann nicht gelöscht werden, da es von den folgenden Ressourcen verwendet wird',
  projectName: 'Projektname',
  allowAlertsForThisProject: 'Benachrichtigungen für dieses Projekt zulassen',
  telegramChatIdOptional: 'Telegram-Chat-ID (optional)',
  maxNumberOfParallelTasksOptional: 'Maximale Anzahl paralleler Aufgaben (optional)',
  deleteRepository: 'Repository löschen',
  newRepository: 'Neues Repository',
  urlOrPath: 'URL oder Pfad',
  absPath: 'absoluter Pfad',
  branch: 'Zweig',
  accessKey: 'Zugriffsschlüssel',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Anmeldeinformationen für den Zugriff auf das Git-Repository. Es sollte sein:',
  ifYouUseGitOrSshUrl: 'wenn Sie Git- oder SSH-URL verwenden.',
  ifYouUseHttpsOrFileUrl: 'wenn Sie HTTPS- oder Datei-URL verwenden.',
  none: 'Keine',
  ssh: 'SSH',
  deleteProject: 'Projekt löschen',
  save: 'Speichern',
  deleteProject2: 'Projekt löschen',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Sobald Sie ein Projekt löschen, gibt es kein Zurück. Bitte seien Sie sich sicher.',
  name2: 'Name *',
  title: 'Titel *',
  description: 'Beschreibung',
  required: 'Erforderlich',
  key: '{expr}',
  surveyVariables: 'Umfragevariablen',
  addVariable: 'Variable hinzufügen',
  vaultName: 'Tresor-ID (Name)',
  vaultNameDefault: 'Es darf nur einen `default` (leeren) Namen geben',
  vaultNameUnique: 'Muss einzigartig sein',
  vaults: 'Tresore',
  vaultAdd: 'Tresor hinzufügen',
  vaultRequired: 'Tresor-Passwort ist erforderlich',
  columns: 'Spalten',
  buildVersion: 'Build-Version',
  messageOptional: 'Nachricht (optional)',
  debug: 'Debug',
  dryRun: 'Trockenlauf',
  diff: 'Differenz',
  advanced: 'Erweitert',
  hide: 'Ausblenden',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Um das Überschreiben von CLI-Argumenten in den Einstellungen der Aufgaben-Vorlage zuzulassen',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'CLI-Argumente (JSON-Array). Beispiel: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Gestartet',
  author: 'Autor',
  duration: 'Dauer',
  stop: 'Stoppen',
  forceStop: 'Zwangsstop',
  confirmTask: 'Bestätigen',
  deleteTeamMember: 'Teammitglied löschen',
  team2: 'Team',
  newTeamMember: 'Neues Teammitglied',
  user: 'Benutzer',
  administrator: 'Administrator',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definiert die Startversion Ihres Artefakts. Jeder Lauf erhöht die Artefaktversion.',
  forMoreInformationAboutBuildingSeeThe: 'Für weitere Informationen zum Erstellen siehe die',
  taskTemplateReference: 'Aufgabenvorlagenreferenz',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Definiert, welches Artefakt bereitgestellt werden soll, wenn die Aufgabe ausgeführt wird.',
  forMoreInformationAboutDeployingSeeThe: 'Für weitere Informationen zum Bereitstellen siehe die',
  taskTemplateReference2: 'Aufgabenvorlagenreferenz',
  definesAutorunSchedule: 'Definiert den automatischen Ausführungszeitplan.',
  forMoreInformationAboutCronSeeThe: 'Für weitere Informationen zu Cron siehe die',
  cronExpressionFormatReference: 'Referenz für das Cron-Ausdrucksformat',
  startVersion: 'Startversion',
  example000: 'Beispiel: 0.0.0',
  buildTemplate: 'Build-Vorlage',
  autorun: 'Automatische Ausführung',
  playbookFilename: 'Playbook-Dateiname *',
  exampleSiteyml: 'Beispiel: site.yml',
  inventory2: 'Inventar *',
  repository: 'Repository',
  environment3: 'Umgebung *',
  vaultPassword: 'Tresor-Passwort',
  vaultPassword2: 'Tresor-Passwort',
  view: 'Ansicht',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Ich möchte eine Aufgabe nur für neue Commits eines bestimmten Repositories über Cron ausführen',
  repository2: 'Repository',
  cronChecksNewCommitBeforeRun: 'Cron überprüft neues Commit vor der Ausführung',
  readThe: 'Lesen Sie die',
  toLearnMoreAboutCron: 'um mehr über Cron zu erfahren.',
  suppressSuccessAlerts: 'Erfolgsbenachrichtigungen unterdrücken',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'CLI-Argumente (JSON-Array). Beispiel: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'CLI-Argumente in der Aufgabe zulassen',
  docs: 'Dokumente',
  editViews: 'Ansichten bearbeiten',
  newTemplate: 'Neue Vorlage',
  taskTemplates2: 'Aufgabenvorlagen',
  all: 'Alle',
  notLaunched: 'Nicht gestartet',
  by: 'von {user_name}',
  editTemplate: 'Vorlage bearbeiten',
  newTemplate2: 'Neue Vorlage',
  deleteTemplate: 'Vorlage löschen',
  playbook: 'Playbook',
  email: 'E-Mail',
  adminUser: 'Admin-Benutzer',
  sendAlerts: 'Benachrichtigungen senden',
  deleteUser: 'Benutzer löschen',
  newUser: 'Neuer Benutzer',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Teammitglied',
  taskId: 'Aufgaben-ID',
  version: 'Version',
  status: 'Status',
  start: 'Start',
  actions: 'Aktionen',
  alert: 'Benachrichtigung',
  admin: 'Admin',
  role: 'Rolle',
  external: 'Extern',
  time: 'Zeit',
  path: 'Pfad',
  gitUrl: 'Git-URL',
  sshKey: 'SSH-Schlüssel',
  lastTask: 'Letzte Aufgabe',
  task2: 'Aufgabe',
  build: 'Bauen',
  deploy: 'Bereitstellen',
  run: 'Ausführen',
  add: 'Hinzufügen',
  password_required: 'Passwort ist erforderlich',
  name_required: 'Name ist erforderlich',
  user_credentials_required: 'Benutzerdaten sind erforderlich',
  type_required: 'Typ ist erforderlich',
  path_required: 'Pfad zur Inventar-Datei ist erforderlich',
  private_key_required: 'Privater Schlüssel ist erforderlich',
  project_name_required: 'Projektname ist erforderlich',
  repository_required: 'Repository ist erforderlich',
  branch_required: 'Zweig ist erforderlich',
  key_required: 'Schlüssel ist erforderlich',
  user_required: 'Benutzer ist erforderlich',
  build_version_required: 'Build-Version ist erforderlich',
  title_required: 'Titel ist erforderlich',
  isRequired: 'ist erforderlich',
  mustBeInteger: 'Muss eine ganze Zahl sein',
  mustBe0OrGreater: 'Muss 0 oder größer sein',
  start_version_required: 'Startversion ist erforderlich',
  playbook_filename_required: 'Playbook-Dateiname ist erforderlich',
  inventory_required: 'Inventar ist erforderlich',
  environment_required: 'Umgebung ist erforderlich',
  email_required: 'E-Mail ist erforderlich',
  build_template_required: 'Build-Vorlage ist erforderlich',
  Task: 'Aufgabe',
  Build: 'Bauen',
  Deploy: 'Bereitstellen',
  Run: 'Ausführen',
  ReBuild: 'Wiederaufbauen',
  ReDeploy: 'Wiederbereitstellen',
  ReRun: 'Wieder ausführen',
  CreateDemoProject: 'Demo-Projekt erstellen',
  LeaveProject: 'Projekt verlassen',
  integration: 'Integration',
  integrations: 'Integrationen',
  NewIntegration: 'Neue Integration',
  EditIntegration: 'Integration bearbeiten',
  DeleteIntegration: 'Integration löschen',
  DeleteIntegrationMsg: 'Sind Sie sicher, dass Sie diese Integration löschen möchten?',
  AddAlias: 'Alias hinzufügen',
  LoadAlias: 'Lade Aliase...',
  globalAlias: 'Verfügbar nach Projekt und globalem Alias',
  matcher: 'Matcher',
  matchType: 'Übereinstimmungstyp',
  newMatcher: 'Neuer Matcher',
  matchMethod: 'Vergleichsmethode',
  matchBodyDataType: 'Körperdaten-Typ',
  extractValue: 'Wert extrahieren',
  newExtractedValue: 'Neuer extrahierter Wert',
  extractedValueSource: 'Wertquelle',
  matchKey: 'Schlüssel',
  matchValue: 'Wert',
  matchOn: 'Übereinstimmung auf',
  runners: 'Runner',
  newRunner: 'Neuer Runner',
  enabled: 'Aktiviert',
  scheduleNextRun: 'Nächster Lauf',
  maxNumberOfParallelTasks: 'Maximale parallele Aufgaben',
  runnerUsage: 'Verwendung:',
  runnerToken: 'Token:',
  editRunner: 'Runner bearbeiten',
  deleteRunner: 'Runner löschen',
  askDeleteRunner: 'Möchten Sie den Runner {runner} wirklich löschen?',
  projectRestoreResult: 'Ergebnisse der Projektwiederherstellung',
  projectWithNameRestored: 'Projekt {projectName} erfolgreich wiederhergestellt.',
  pleaseUpdateAccessKeys: 'Bitte aktualisieren Sie diese, bevor Sie Aufgaben ausführen.',
  emptyKeysRestored: '{emptyKeys} leere Schlüssel hinzugefügt.',
  template: 'Vorlage',
  aliasUrlCopied: 'Die Alias-URL wurde in die Zwischenablage kopiert.',
  yes: 'Ja',
  activeTasks: 'Aktive Aufgaben',
  taskLocation: 'Standort',
  empty: 'Leer',
  noValues: 'Keine Werte',
};
