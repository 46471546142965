var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tasks != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":_vm.$t('Re' + _vm.getActionButtonTitle())},on:{"save":_vm.onTaskCreated},scopedSlots:_vm._u([{key:"title",fn:function(ref){return [_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.TEMPLATE_TYPE_ICONS[_vm.template.type]))]),_c('span',{staticClass:"breadcrumbs__item"},[_vm._v(_vm._s(_vm.template.name))]),_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('span',{staticClass:"breadcrumbs__item"},[_vm._v(_vm._s(_vm.$t('newTask')))])]}},{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [(['terraform', 'tofu'].includes(_vm.template.app))?_c('TerraformTaskForm',{attrs:{"project-id":_vm.template.project_id,"item-id":"new","template-id":_vm.template.id,"need-save":needSave,"need-reset":needReset,"source-task":_vm.sourceTask},on:{"save":onSave,"error":onError}}):_c('TaskForm',{attrs:{"project-id":_vm.template.project_id,"item-id":"new","template-id":_vm.template.id,"need-save":needSave,"need-reset":needReset,"source-task":_vm.sourceTask},on:{"save":onSave,"error":onError}})]}}],null,false,507024452),model:{value:(_vm.newTaskDialog),callback:function ($$v) {_vm.newTaskDialog=$$v},expression:"newTaskDialog"}}),_c('v-data-table',{staticClass:"mt-0",attrs:{"headers":_vm.headers,"items":_vm.tasks,"hide-default-footer":_vm.hideFooter,"footer-props":{ itemsPerPageOptions: [20] }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('TaskLink',{attrs:{"task-id":item.id,"tooltip":item.message,"label":'#' + item.id}})]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [(item.tpl_type !== '')?_c('div',[_c('TaskLink',{attrs:{"disabled":item.tpl_type === 'build',"task-id":item.build_task_id,"tooltip":item.tpl_type === 'build' ? item.message : (item.build_task || {}).message,"label":item.tpl_type === 'build' ? item.version : (item.build_task || {}).version,"status":item.status}})],1):_c('div',[_vm._v("—")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('TaskStatus',{attrs:{"status":item.status}})]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMilliseconds")([item.start, item.end]))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pl-1 pr-2",attrs:{"text":""},on:{"click":function($event){return _vm.createTask(item)}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-replay")]),_vm._v(" "+_vm._s(_vm.getActionButtonTitle())+" ")],1)]}}],null,false,4078624395)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }