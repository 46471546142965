export default {
  checkInterval: '체크 간격',
  newCommitCheckInterval: '새 커밋 체크 간격',
  schedule: '일정',
  newSchedule: '새 일정',
  deleteSchedule: '일정 삭제',
  editSchedule: '일정 수정',
  backup: '프로젝트 백업',
  downloadTheProjectBackupFile: '프로젝트 백업 파일 다운로드 (json 형식)',
  restoreProject: '프로젝트 복원...',
  incorrectUsrPwd: '잘못된 로그인 또는 비밀번호',
  askDeleteUser: '정말로 이 사용자를 삭제하시겠습니까?',
  askDeleteTemp: '정말로 이 템플릿을 삭제하시겠습니까?',
  askDeleteEnv: '정말로 이 환경을 삭제하시겠습니까?',
  askDeleteInv: '정말로 이 인벤토리를 삭제하시겠습니까?',
  askDeleteKey: '정말로 이 키를 삭제하시겠습니까?',
  askDeleteRepo: '정말로 이 리포지토리를 삭제하시겠습니까?',
  askDeleteProj: '정말로 이 프로젝트를 삭제하시겠습니까?',
  askDeleteTMem: '정말로 이 팀 멤버를 삭제하시겠습니까?',
  askDeleteSchedule: '정말로 이 일정을 삭제하시겠습니까?',
  edit: '수정',
  nnew: '새로 만들기',
  keyFormSshKey: 'SSH 키',
  keyFormLoginPassword: '비밀번호로 로그인',
  keyFormNone: '없음',
  incorrectUrl: '잘못된 URL',
  username: '사용자 이름',
  username_required: '사용자 이름은 필수입니다',
  dashboard: '대시보드',
  history: '기록',
  activity: '활동',
  settings: '설정',
  signIn: '로그인',
  password: '비밀번호',
  changePassword: '비밀번호 변경',
  editUser: '사용자 수정',
  newProject: '새 프로젝트',
  close: '닫기',
  newProject2: '새 프로젝트...',
  demoMode: '데모 모드',
  task: '작업 #{expr}',
  youCanRunAnyTasks: '모든 작업을 실행할 수 있습니다',
  youHaveReadonlyAccess: '읽기 전용 접근 권한이 있습니다',
  taskTemplates: '작업 템플릿',
  inventory: '인벤토리',
  environment: '환경 변수',
  keyStore: '키 저장소',
  repositories: '리포지토리',
  darkMode: '다크 모드',
  team: '팀',
  users: '사용자',
  editAccount: '계정 수정',
  signOut: '로그아웃',
  error: '오류',
  refreshPage: '페이지 새로 고침',
  relogin: '다시 로그인',
  howToFixSigninIssues: '로그인 문제를 해결하는 방법',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: '먼저 Semaphore가 실행되는 서버에 접근해야 합니다.',
  executeTheFollowingCommandOnTheServerToSeeExisting: '서버에서 다음 명령을 실행하여 기존 사용자를 확인하세요:',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: '기존 사용자의 비밀번호를 변경할 수 있습니다:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: '또는 새 관리자 사용자를 생성하세요:',
  close2: '닫기',
  semaphore: '세마포어',
  dontHaveAccountOrCantSignIn: '계정이 없거나 로그인할 수 없습니까?',
  password2: '비밀번호',
  cancel: '취소',
  noViews: '보기 없음',
  addView: '보기 추가',
  editEnvironment: '환경 수정',
  deleteEnvironment: '환경 삭제',
  newEnvironment: '새 환경',
  environmentName: '환경 이름',
  extraVariables: '추가 변수',
  enterExtraVariablesJson: '추가 변수 JSON 입력...',
  environmentVariables: '환경 변수',
  enterEnvJson: 'env JSON 입력...',
  environmentAndExtraVariablesMustBeValidJsonExample: '환경 및 추가 변수는 유효한 JSON이어야 합니다. 예:',
  dashboard2: '대시보드',
  ansibleSemaphore: '세마포어 UI',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: '죄송하지만 <%= htmlWebpackPlugin.options.title %>는 JavaScript가 활성화되지 않으면 제대로 작동하지 않습니다. 계속하려면 활성화해 주세요.',
  deleteInventory: '인벤토리 삭제',
  newInventory: '새 인벤토리',
  name: '이름',
  userCredentials: '사용자 자격 증명',
  sudoCredentialsOptional: 'Sudo 자격 증명 (선택 사항)',
  type: '유형',
  pathToInventoryFile: '인벤토리 파일 경로',
  enterInventory: '인벤토리 입력...',
  staticInventoryExample: '정적 인벤토리 예:',
  staticYamlInventoryExample: '정적 YAML 인벤토리 예:',
  keyName: '키 이름',
  loginOptional: '로그인 (선택 사항)',
  usernameOptional: '사용자 이름 (선택 사항)',
  privateKey: '개인 키',
  override: '재정의',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'HTTPS 리포지토리 및 비 SSH 연결을 사용하는 플레이북에 이 유형의 키를 사용하세요.',
  deleteKey: '키 삭제',
  newKey: '새 키',
  create: '생성',
  newTask: '새 작업',
  cantDeleteThe: '{objectTitle}을(를) 삭제할 수 없습니다',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle}은(는) 아래 리소스에서 사용되고 있어 삭제할 수 없습니다',
  projectName: '프로젝트 이름',
  allowAlertsForThisProject: '이 프로젝트에 대한 알림 허용',
  telegramChatIdOptional: '텔레그램 채팅 ID (선택 사항)',
  maxNumberOfParallelTasksOptional: '최대 병렬 작업 수 (선택 사항)',
  deleteRepository: '리포지토리 삭제',
  newRepository: '새 리포지토리',
  urlOrPath: 'URL 또는 경로',
  absPath: '절대 경로',
  branch: '브랜치',
  accessKey: '접근 키',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Git 리포지토리에 접근하기 위한 자격 증명. 다음과 같아야 합니다:',
  ifYouUseGitOrSshUrl: 'Git 또는 SSH URL을 사용하는 경우.',
  ifYouUseHttpsOrFileUrl: 'HTTPS 또는 파일 URL을 사용하는 경우.',
  none: '없음',
  ssh: 'SSH',
  deleteProject: '프로젝트 삭제',
  save: '저장',
  deleteProject2: '프로젝트 삭제',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: '프로젝트를 삭제하면 되돌릴 수 없습니다. 확실히 하세요.',
  name2: '이름 *',
  title: '제목 *',
  description: '설명',
  required: '필수',
  key: '{expr}',
  surveyVariables: '설문 변수',
  addVariable: '변수 추가',
  vaultName: '금고 ID (이름)',
  vaultNameDefault: '하나의 `default` (빈) 이름만 존재할 수 있습니다',
  vaultNameUnique: '고유해야 합니다',
  vaults: '금고',
  vaultAdd: '금고 추가',
  vaultRequired: '금고 비밀번호는 필수입니다',
  columns: '열',
  buildVersion: '빌드 버전',
  messageOptional: '메시지 (선택 사항)',
  debug: '디버그',
  dryRun: 'Dry Run',
  diff: '차이',
  advanced: '고급',
  hide: '숨기기',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: '작업 템플릿 설정에서 CLI 인수를 재정의할 수 있도록 허용하세요',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'CLI 인수 (JSON 배열). 예: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: '시작됨',
  author: '작성자',
  duration: '지속 시간',
  stop: '중지',
  forceStop: '강제 중지',
  confirmTask: '확인',
  deleteTeamMember: '팀 멤버 삭제',
  team2: '팀',
  newTeamMember: '새 팀 멤버',
  user: '사용자',
  administrator: '관리자',
  definesStartVersionOfYourArtifactEachRunIncrements: '아티팩트의 시작 버전을 정의합니다. 각 실행 시 아티팩트 버전이 증가합니다.',
  forMoreInformationAboutBuildingSeeThe: '빌드에 대한 자세한 정보는 다음을 참조하세요',
  taskTemplateReference: '작업 템플릿 참조',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: '작업이 실행될 때 배포해야 할 아티팩트를 정의합니다.',
  forMoreInformationAboutDeployingSeeThe: '배포에 대한 자세한 정보는 다음을 참조하세요',
  taskTemplateReference2: '작업 템플릿 참조',
  definesAutorunSchedule: '자동 실행 일정을 정의합니다.',
  forMoreInformationAboutCronSeeThe: 'Cron에 대한 자세한 정보는 다음을 참조하세요',
  cronExpressionFormatReference: 'Cron 표현식 형식 참조',
  startVersion: '시작 버전',
  example000: '예: 0.0.0',
  buildTemplate: '빌드 템플릿',
  autorun: '자동 실행',
  playbookFilename: '플레이북 파일 이름 *',
  exampleSiteyml: '예: site.yml',
  inventory2: '인벤토리 *',
  repository: '리포지토리',
  environment3: '환경 *',
  vaultPassword: '금고 비밀번호',
  vaultPassword2: '금고 비밀번호',
  view: '보기',
  cron: '크론',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: '특정 리포지토리의 새 커밋에 대해서만 크론으로 작업을 실행하고 싶습니다',
  repository2: '리포지토리',
  cronChecksNewCommitBeforeRun: '크론이 실행 전에 새 커밋을 확인합니다',
  readThe: '읽기',
  toLearnMoreAboutCron: '크론에 대해 더 알아보세요.',
  suppressSuccessAlerts: '성공 알림 억제',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'CLI 인수 (JSON 배열). 예: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: '작업에서 CLI 인수 허용',
  docs: '문서',
  editViews: '보기 수정',
  newTemplate: '새 템플릿',
  taskTemplates2: '작업 템플릿',
  all: '모두',
  notLaunched: '시작되지 않음',
  by: '{user_name}에 의해',
  editTemplate: '템플릿 수정',
  newTemplate2: '새 템플릿',
  deleteTemplate: '템플릿 삭제',
  playbook: '플레이북',
  email: '이메일',
  adminUser: '관리자 사용자',
  sendAlerts: '알림 전송',
  deleteUser: '사용자 삭제',
  newUser: '새 사용자',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} 팀 멤버',
  taskId: '작업 ID',
  version: '버전',
  status: '상태',
  start: '시작',
  actions: '작업',
  alert: '알림',
  admin: '관리자',
  role: '역할',
  external: '외부',
  time: '시간',
  path: '경로',
  gitUrl: 'Git URL',
  sshKey: 'SSH 키',
  lastTask: '마지막 작업',
  task2: '작업',
  build: '빌드',
  deploy: '배포',
  run: '실행',
  add: '추가',
  password_required: '비밀번호는 필수입니다',
  name_required: '이름은 필수입니다',
  user_credentials_required: '사용자 자격 증명은 필수입니다',
  type_required: '유형은 필수입니다',
  path_required: '인벤토리 파일 경로는 필수입니다',
  private_key_required: '개인 키는 필수입니다',
  project_name_required: '프로젝트 이름은 필수입니다',
  repository_required: '리포지토리는 필수입니다',
  branch_required: '브랜치는 필수입니다',
  key_required: '키는 필수입니다',
  user_required: '사용자는 필수입니다',
  build_version_required: '빌드 버전은 필수입니다',
  title_required: '제목은 필수입니다',
  isRequired: '필수입니다',
  mustBeInteger: '정수여야 합니다',
  mustBe0OrGreater: '0 이상이어야 합니다',
  start_version_required: '시작 버전은 필수입니다',
  playbook_filename_required: '플레이북 파일 이름은 필수입니다',
  inventory_required: '인벤토리는 필수입니다',
  environment_required: '환경은 필수입니다',
  email_required: '이메일은 필수입니다',
  build_template_required: '빌드 템플릿은 필수입니다',
  Task: '작업',
  Build: '빌드',
  Deploy: '배포',
  Run: '실행',
  ReBuild: '재빌드',
  ReDeploy: '재배포',
  ReRun: '재실행',
  CreateDemoProject: '데모 프로젝트 생성',
  LeaveProject: '프로젝트 나가기',
  integration: '통합',
  integrations: '통합',
  NewIntegration: '새 통합',
  EditIntegration: '통합 수정',
  DeleteIntegration: '통합 삭제',
  DeleteIntegrationMsg: '정말로 이 통합을 삭제하시겠습니까?',
  AddAlias: '별칭 추가',
  LoadAlias: '별칭 로딩 중...',
  globalAlias: '프로젝트별 및 전역 별칭 사용 가능',
  matcher: '매처',
  matchType: '일치 유형',
  newMatcher: '새 매처',
  matchMethod: '비교 방법',
  matchBodyDataType: '본문 데이터 유형',
  extractValue: '값 추출',
  newExtractedValue: '새로 추출된 값',
  extractedValueSource: '값 출처',
  matchKey: '키',
  matchValue: '값',
  matchOn: '일치 기준',
  runners: '러너',
  newRunner: '새 러너',
  enabled: '활성화됨',
  scheduleNextRun: '다음 실행',
  maxNumberOfParallelTasks: '최대 병렬 작업 수',
  runnerUsage: '사용:',
  runnerToken: '토큰:',
  editRunner: '러너 수정',
  deleteRunner: '러너 삭제',
  askDeleteRunner: '정말로 러너 {runner}를 삭제하시겠습니까?',
  projectRestoreResult: '프로젝트 복원 결과',
  projectWithNameRestored: '프로젝트 {projectName}이(가) 성공적으로 복원되었습니다.',
  pleaseUpdateAccessKeys: '작업을 실행하기 전에 접근 키를 업데이트하세요.',
  emptyKeysRestored: '{emptyKeys} 개의 빈 키가 추가되었습니다.',
  template: '템플릿',
  aliasUrlCopied: '별칭 URL이 클립보드에 복사되었습니다.',
  yes: '예',
  activeTasks: '활성 작업',
  taskLocation: '위치',
  empty: '비어 있음',
  noValues: '값 없음',
};
