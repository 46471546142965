export default {
  checkInterval: 'interwałSprawdzania',
  newCommitCheckInterval: 'Interwał sprawdzania nowych commitów',
  schedule: 'Harmonogram',
  newSchedule: 'Nowy harmonogram',
  deleteSchedule: 'Usuń harmonogram',
  editSchedule: 'Edytuj harmonogram',
  backup: 'Kopia zapasowa projektu',
  downloadTheProjectBackupFile: 'Pobierz plik kopii zapasowej projektu (w formacie json)',
  restoreProject: 'Przywróć projekt...',
  incorrectUsrPwd: 'Niepoprawny login lub hasło',
  askDeleteUser: 'Czy na pewno chcesz usunąć tego użytkownika?',
  askDeleteTemp: 'Czy na pewno chcesz usunąć ten szablon?',
  askDeleteEnv: 'Czy na pewno chcesz usunąć to środowisko?',
  askDeleteInv: 'Czy na pewno chcesz usunąć ten inwentarz?',
  askDeleteKey: 'Czy na pewno chcesz usunąć ten klucz?',
  askDeleteRepo: 'Czy na pewno chcesz usunąć to repozytorium?',
  askDeleteProj: 'Czy na pewno chcesz usunąć ten projekt?',
  askDeleteTMem: 'Czy na pewno chcesz usunąć tego członka zespołu?',
  askDeleteSchedule: 'Czy na pewno chcesz usunąć ten harmonogram?',
  edit: 'Edytuj',
  nnew: 'Nowy',
  keyFormSshKey: 'Klucz SSH',
  keyFormLoginPassword: 'Login z hasłem',
  keyFormNone: 'Brak',
  incorrectUrl: 'Niepoprawny URL',
  username: 'Nazwa użytkownika',
  username_required: 'Nazwa użytkownika jest wymagana',
  dashboard: 'Panel',
  history: 'Historia',
  activity: 'Aktywność',
  settings: 'Ustawienia',
  signIn: 'Zaloguj się',
  password: 'Hasło',
  changePassword: 'Zmień hasło',
  editUser: 'Edytuj użytkownika',
  newProject: 'Nowy projekt',
  close: 'Zamknij',
  newProject2: 'Nowy projekt...',
  demoMode: 'TRYB DEMO',
  task: 'Zadanie #{expr}',
  youCanRunAnyTasks: 'Możesz uruchomić dowolne zadania',
  youHaveReadonlyAccess: 'Masz dostęp tylko do odczytu',
  taskTemplates: 'Szablony zadań',
  inventory: 'Inwentarz',
  environment: 'Zmienne środowiskowe',
  keyStore: 'Magazyn kluczy',
  repositories: 'Repozytoria',
  darkMode: 'Tryb ciemny',
  team: 'Zespół',
  users: 'Użytkownicy',
  editAccount: 'Edytuj konto',
  signOut: 'Wyloguj się',
  error: 'Błąd',
  refreshPage: 'Odśwież stronę',
  relogin: 'Ponowne logowanie',
  howToFixSigninIssues: 'Jak naprawić problemy z logowaniem',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Po pierwsze, musisz mieć dostęp do serwera, na którym działa Semaphore.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Wykonaj następujące polecenie na serwerze, aby zobaczyć istniejących użytkowników:',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: 'Możesz zmienić hasło istniejącego użytkownika:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'Lub utwórz nowego użytkownika administratora:',
  close2: 'Zamknij',
  semaphore: 'SEMAFOR',
  dontHaveAccountOrCantSignIn: 'Nie masz konta lub nie możesz się zalogować?',
  password2: 'Hasło',
  cancel: 'Anuluj',
  noViews: 'Brak widoków',
  addView: 'Dodaj widok',
  editEnvironment: 'Edytuj środowisko',
  deleteEnvironment: 'Usuń środowisko',
  newEnvironment: 'Nowe środowisko',
  environmentName: 'Nazwa środowiska',
  extraVariables: 'Dodatkowe zmienne',
  enterExtraVariablesJson: 'Wprowadź dodatkowe zmienne JSON...',
  environmentVariables: 'Zmienne środowiskowe',
  enterEnvJson: 'Wprowadź JSON środowiska...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Środowisko i dodatkowe zmienne muszą być poprawnym JSON-em. Przykład:',
  dashboard2: 'Panel',
  ansibleSemaphore: 'Interfejs Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Przykro nam, ale <%= htmlWebpackPlugin.options.title %> nie działa poprawnie bez włączonego JavaScriptu. Proszę włączyć go, aby kontynuować.',
  deleteInventory: 'Usuń inwentarz',
  newInventory: 'Nowy inwentarz',
  name: 'Nazwa',
  userCredentials: 'Dane logowania użytkownika',
  sudoCredentialsOptional: 'Dane logowania sudo (opcjonalne)',
  type: 'Typ',
  pathToInventoryFile: 'Ścieżka do pliku inwentarza',
  enterInventory: 'Wprowadź inwentarz...',
  staticInventoryExample: 'Przykład statycznego inwentarza:',
  staticYamlInventoryExample: 'Przykład statycznego inwentarza YAML:',
  keyName: 'Nazwa klucza',
  loginOptional: 'Login (opcjonalne)',
  usernameOptional: 'Nazwa użytkownika (opcjonalne)',
  privateKey: 'Klucz prywatny',
  override: 'Nadpisz',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Użyj tego typu klucza dla repozytoriów HTTPS i dla playbooków, które używają połączeń nie-SSH.',
  deleteKey: 'Usuń klucz',
  newKey: 'Nowy klucz',
  create: 'Utwórz',
  newTask: 'Nowe zadanie',
  cantDeleteThe: 'Nie można usunąć {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} nie może być usunięty, ponieważ jest używany przez poniższe zasoby',
  projectName: 'Nazwa projektu',
  allowAlertsForThisProject: 'Zezwól na powiadomienia dla tego projektu',
  telegramChatIdOptional: 'ID czatu Telegram (opcjonalne)',
  maxNumberOfParallelTasksOptional: 'Maksymalna liczba równoległych zadań (opcjonalne)',
  deleteRepository: 'Usuń repozytorium',
  newRepository: 'Nowe repozytorium',
  urlOrPath: 'URL lub ścieżka',
  absPath: 'absolutna ścieżka',
  branch: 'Gałąź',
  accessKey: 'Klucz dostępu',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Dane logowania do uzyskania dostępu do repozytorium Git. Powinny być:',
  ifYouUseGitOrSshUrl: 'jeśli używasz URL Git lub SSH.',
  ifYouUseHttpsOrFileUrl: 'jeśli używasz URL HTTPS lub pliku.',
  none: 'Brak',
  ssh: 'SSH',
  deleteProject: 'Usuń projekt',
  save: 'Zapisz',
  deleteProject2: 'Usuń projekt',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Gdy usuniesz projekt, nie ma powrotu. Proszę być pewnym.',
  name2: 'Nazwa *',
  title: 'Tytuł *',
  description: 'Opis',
  required: 'Wymagane',
  key: '{expr}',
  surveyVariables: 'Zmienne ankiety',
  addVariable: 'Dodaj zmienną',
  vaultName: 'ID skarbca (nazwa)',
  vaultNameDefault: 'Może istnieć tylko jedna nazwa `default` (pusta)',
  vaultNameUnique: 'Musi być unikalna',
  vaults: 'Skarbce',
  vaultAdd: 'Dodaj skarbiec',
  vaultRequired: 'Hasło skarbca jest wymagane',
  columns: 'Kolumny',
  buildVersion: 'Wersja kompilacji',
  messageOptional: 'Wiadomość (opcjonalne)',
  debug: 'Debug',
  dryRun: 'Symulacja',
  diff: 'Różnice',
  advanced: 'Zaawansowane',
  hide: 'Ukryj',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Aby zezwolić na nadpisanie argumentu CLI w ustawieniach szablonu zadania',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Argumenty CLI (tablica JSON). Przykład: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Rozpoczęto',
  author: 'Autor',
  duration: 'Czas trwania',
  stop: 'Zatrzymaj',
  forceStop: 'Wymuś zatrzymanie',
  confirmTask: 'Potwierdź',
  deleteTeamMember: 'Usuń członka zespołu',
  team2: 'Zespół',
  newTeamMember: 'Nowy członek zespołu',
  user: 'Użytkownik',
  administrator: 'Administrator',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definiuje początkową wersję twojego artefaktu. Każde uruchomienie zwiększa wersję artefaktu.',
  forMoreInformationAboutBuildingSeeThe: 'Aby uzyskać więcej informacji na temat budowania, zobacz',
  taskTemplateReference: 'Odwołanie do szablonu zadania',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Definiuje, jaki artefakt powinien być wdrożony, gdy zadanie jest uruchamiane.',
  forMoreInformationAboutDeployingSeeThe: 'Aby uzyskać więcej informacji na temat wdrażania, zobacz',
  taskTemplateReference2: 'Odwołanie do szablonu zadania',
  definesAutorunSchedule: 'Definiuje harmonogram automatycznego uruchamiania.',
  forMoreInformationAboutCronSeeThe: 'Aby uzyskać więcej informacji na temat cron, zobacz',
  cronExpressionFormatReference: 'Odwołanie do formatu wyrażenia cron',
  startVersion: 'Wersja początkowa',
  example000: 'Przykład: 0.0.0',
  buildTemplate: 'Szablon budowy',
  autorun: 'Automatyczne uruchamianie',
  playbookFilename: 'Nazwa pliku playbook *',
  exampleSiteyml: 'Przykład: site.yml',
  inventory2: 'Inwentarz *',
  repository: 'Repozytorium',
  environment3: 'Środowisko *',
  vaultPassword: 'Hasło skarbca',
  vaultPassword2: 'Hasło skarbca',
  view: 'Widok',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Chcę uruchomić zadanie przez cron tylko dla nowych commitów z niektórego repozytorium',
  repository2: 'Repozytorium',
  cronChecksNewCommitBeforeRun: 'Cron sprawdza nowy commit przed uruchomieniem',
  readThe: 'Przeczytaj',
  toLearnMoreAboutCron: 'aby dowiedzieć się więcej o Cron.',
  suppressSuccessAlerts: 'Tłumienie powiadomień o sukcesie',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Argumenty CLI (tablica JSON). Przykład: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Zezwól na argumenty CLI w zadaniu',
  docs: 'dokumentacja',
  editViews: 'Edytuj widoki',
  newTemplate: 'Nowy szablon',
  taskTemplates2: 'Szablony zadań',
  all: 'Wszystko',
  notLaunched: 'Nie uruchomiono',
  by: 'przez {user_name}',
  editTemplate: 'Edytuj szablon',
  newTemplate2: 'Nowy szablon',
  deleteTemplate: 'Usuń szablon',
  playbook: 'Playbook',
  email: 'Email',
  adminUser: 'Użytkownik administratora',
  sendAlerts: 'Wyślij powiadomienia',
  deleteUser: 'Usuń użytkownika',
  newUser: 'Nowy użytkownik',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} członek zespołu',
  taskId: 'ID zadania',
  version: 'Wersja',
  status: 'Status',
  start: 'Rozpocznij',
  actions: 'Akcje',
  alert: 'Powiadomienie',
  admin: 'Administrator',
  role: 'Rola',
  external: 'Zewnętrzny',
  time: 'Czas',
  path: 'Ścieżka',
  gitUrl: 'URL Git',
  sshKey: 'Klucz SSH',
  lastTask: 'Ostatnie zadanie',
  task2: 'Zadanie',
  build: 'Buduj',
  deploy: 'Wdrażaj',
  run: 'Uruchom',
  add: 'Dodaj',
  password_required: 'Hasło jest wymagane',
  name_required: 'Nazwa jest wymagana',
  user_credentials_required: 'Dane logowania użytkownika są wymagane',
  type_required: 'Typ jest wymagany',
  path_required: 'Ścieżka do pliku inwentarza jest wymagana',
  private_key_required: 'Klucz prywatny jest wymagany',
  project_name_required: 'Nazwa projektu jest wymagana',
  repository_required: 'Repozytorium jest wymagane',
  branch_required: 'Gałąź jest wymagana',
  key_required: 'Klucz jest wymagany',
  user_required: 'Użytkownik jest wymagany',
  build_version_required: 'Wersja budowy jest wymagana',
  title_required: 'Tytuł jest wymagany',
  isRequired: 'jest wymagane',
  mustBeInteger: 'Musi być liczbą całkowitą',
  mustBe0OrGreater: 'Musi być 0 lub większa',
  start_version_required: 'Wersja początkowa jest wymagana',
  playbook_filename_required: 'Nazwa pliku playbook jest wymagana',
  inventory_required: 'Inwentarz jest wymagany',
  environment_required: 'Środowisko jest wymagane',
  email_required: 'Email jest wymagany',
  build_template_required: 'Szablon budowy jest wymagany',
  Task: 'Zadanie',
  Build: 'Buduj',
  Deploy: 'Wdrażaj',
  Run: 'Uruchom',
  ReBuild: 'Przebuduj',
  ReDeploy: 'Ponownie wdrażaj',
  ReRun: 'Uruchom ponownie',
  CreateDemoProject: 'Utwórz projekt demo',
  LeaveProject: 'Opuść projekt',
  integration: 'Integracja',
  integrations: 'Integracje',
  NewIntegration: 'Nowa integracja',
  EditIntegration: 'Edytuj integrację',
  DeleteIntegration: 'Usuń integrację',
  DeleteIntegrationMsg: 'Czy na pewno chcesz usunąć tę integrację?',
  AddAlias: 'Dodaj alias',
  LoadAlias: 'Ładowanie aliasów...',
  globalAlias: 'Dostępny przez projekt i globalny alias',
  matcher: 'Dopasowacz',
  matchType: 'Typ dopasowania',
  newMatcher: 'Nowy dopasowacz',
  matchMethod: 'Metoda porównania',
  matchBodyDataType: 'Typ danych ciała',
  extractValue: 'Wyodrębnij wartość',
  newExtractedValue: 'Nowa wyodrębniona wartość',
  extractedValueSource: 'Źródło wartości',
  matchKey: 'Klucz',
  matchValue: 'Wartość',
  matchOn: 'Dopasuj na',
  runners: 'Uruchamiacze',
  newRunner: 'Nowy uruchamiacz',
  enabled: 'Włączony',
  scheduleNextRun: 'Następne uruchomienie',
  maxNumberOfParallelTasks: 'Maksymalna liczba równoległych zadań',
  runnerUsage: 'Użycie:',
  runnerToken: 'Token:',
  editRunner: 'Edytuj uruchamiacz',
  deleteRunner: 'Usuń uruchamiacz',
  askDeleteRunner: 'Czy na pewno chcesz usunąć uruchamiacz {runner}?',
  projectRestoreResult: 'Wyniki przywracania projektu',
  projectWithNameRestored: 'Projekt {projectName} został pomyślnie przywrócony.',
  pleaseUpdateAccessKeys: 'Proszę zaktualizować je przed uruchomieniem zadań.',
  emptyKeysRestored: '{emptyKeys} puste klucze dodane.',
  template: 'Szablon',
  aliasUrlCopied: 'URL aliasu został skopiowany do schowka.',
  yes: 'Tak',
  activeTasks: 'Aktywne zadania',
  taskLocation: 'Lokalizacja',
  empty: 'Pusty',
  noValues: 'Brak wartości',
};
