var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){return _vm.returnToProjects()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('activeTasks')))])],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20] }},scopedSlots:_vm._u([{key:"item.task_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/project/' + item.project_id + '?t=' + item.task_id}},[_vm._v(" #"+_vm._s(item.task_id)+" ")])]}},{key:"item.project_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/project/' + item.project_id}},[_vm._v(" #"+_vm._s(item.project_id)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_c('TaskStatus',{attrs:{"status":item.status}})],1)]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [(item.location === 'queue')?_c('div',[_vm._v("Queue")]):(item.runner_id)?_c('div',[_vm._v(" Runner #"+_vm._s(item.runner_id)+" ")]):_c('div',[_vm._v("Local Running")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.task_id)}}},[_c('v-icon',[_vm._v("mdi-stop")])],1)],1)]}}],null,false,3235746182)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }